
<template>
  <div :id="id" :class="[styleClass]">
    <label :class="[labelStyleClass]" v-if="!suppressLabel">{{ $t('field.color') }}</label>
    <div class="d-flex select-button">
      <div :id="`COLOR_FOREGROUND_${id}`"
           class="color-style color-foreground"  
           @click="onClick"
           :class="value === null || value === '' ? 'color-none' : ''"
           :style="value === null || value === '' ? '' : `background-color: ${value}`"/>
     <b-popover
        :target="`COLOR_FOREGROUND_${id}`"
        placement="top"
        triggers="hover"
        :content="`${$t('field.color')}: ${value ? value : $t('none')}`">
      </b-popover>
    </div>
      
    <ColorModal :show="colorShow"
      :color="value"
      @changed="changed"
      :update="update"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { strRandom } from '@/helpers';

export default {
  name: 'Color',
  components: {
    ColorModal: () => import('@/components/modal/ColorModal.vue')
  },
  props: {
    value: {
      type: String,
      default: null
    },
    update: {
      type: String,
      default: null
    },
    styleClass: {
      type: String,
      default: 'mb-3'
    },
    suppressLabel: {
      type: Boolean,
      default: false
    },
    labelStyleClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      id: `COLOR_${strRandom(5)}`,
      colorShow: false
    };
  },
  watch: {
    update(newValue) {
      if (newValue) {
        this.update = newValue;
      }
    }
  },
  methods: {
    changed (value) {
      this.colorShow = false;
      this.$emit('input', value === '' ? null : value)
    },
    cancel() {
      this.colorShow = false;
    },
    onClick() {
      if (!this.disabled) {
        this.colorShow = true;
      }
    }
  }
}


</script>

<style lang="scss">
  .form-control.color-text {
    flex: none;
    width: 84px;
    display: inline-block;
  }
  
  .form-control.color-style {
    flex: none;
    vertical-align: middle;
    width: 35px;
    display: inline-block;
  }
  
  .form-control.color-style:focus {
    box-shadow: none;
  }
  
  .color-hidden {
    opacity: 0;
  }
  
  .select-button {
    padding: 3px;
    width: fit-content;
    border: 1px solid var(--form-control-border);
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-button:focus,
  .select-button:active,
  .select-button:hover {
    background: var(--backdrop);
    border-radius: 3px;
    border: 1px solid var(--border-dark);
  }
  
  .color-foreground {
    height: 27px;
    width: 27px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
  }
  
</style>